import React from "react";
import classNames from "classnames";

const FormInput = ({
  label,
  id,
  type,
  placeholder,
  required,
  value,
  onChange,
  children,
  className,
  ...rest
}) => {
  return (
    <div>
      <label
        htmlFor={id}
        className="block my-2 text-sm font-medium text-gray-900"
      >
        {label}
      </label>
      <input
        type={type}
        id={id}
        className={classNames(
          "bg-gray-50",
          "border border-gray-300",
          "text-gray-900",
          "text-sm",
          "rounded-lg",
          "focus:ring-blue-500",
          "focus:border-blue-500",
          "block",
          "w-full",
          "p-2.5",
          "placeholder-gray-400",
          "focus:ring-blue-500",
          "focus:border-blue-500",
          className
        )}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        {...rest}
      >
        {children}
      </input>
    </div>
  );
};

export default FormInput;
