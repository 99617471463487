import axios from "axios";
import classNames from "classnames";
import React, { useState } from "react";
import ReactModal from "react-modal";
import FormInput from "./FormInput";

const ChangeLocationDialog = ({ activity, isOpen, handleCloseDialog }) => {
  const [name, setName] = useState(activity?.name);
  const [description, setDescription] = useState(activity?.description);
  const [website, setWebsite] = useState(activity?.website);
  const [loading, setLoading] = useState(false);

  const handleName = (event) => {
    setName(event.target.value);
  };

  const handleWebsite = (event) => {
    setWebsite(event.target.value);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const result = await axios.post("/.netlify/functions/ChangeItem", {
      id: activity.id,
      name: name,
      type: activity.type,
      latitude: activity.latitude,
      longitude: activity.longitude,
      website: website,
      description: description,
    });

    setLoading(false);
    if (result.status === 200) {
      handleCloseDialog();
    }
  };

  return (
    <ReactModal isOpen={isOpen} style={{ overlay: { zIndex: 9999 } }}>
      <h2>Takk for at du rydder opp!❤️</h2>
      <br />
      <form onSubmit={handleSubmit}>
        <FormInput
          type="text"
          label={"Gi det et navn"}
          value={name}
          placeholder={activity?.name}
          onChange={handleName}
          className={"mb-2"}
        />
        <FormInput
          type="text"
          label={"Nettside"}
          value={website}
          placeholder={activity?.website}
          onChange={handleWebsite}
          className={"mb-2"}
        />
        <label
          htmlFor={"beskrivelse"}
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Beskrivelse
        </label>
        <textarea
          id={"beskrivelse"}
          className={classNames(
            "bg-gray-50",
            "border border-gray-300",
            "text-gray-900",
            "text-sm",
            "rounded-lg",
            "focus:ring-blue-500",
            "focus:border-blue-500",
            "block",
            "w-full",
            "p-2.5",
            "placeholder-gray-400",
            "focus:ring-blue-500",
            "focus:border-blue-500",
            "mb-2"
          )}
          placeholder={activity?.description}
          required
          value={description}
          onChange={handleDescription}
        />
        <>
          <button
            className="text-white font-medium rounded-lg text-md px-2 py-2.5 text-center inline-flex items-center mr-2 sm:m-2 bg-green-600 hover:bg-green-700 focus:ring-green-800"
            type="submit"
            disabled={loading}
          >
            {(!loading && "Foreslå endring") || "Sender inn..."}
          </button>
          <button
            type="button"
            className="text-white font-medium rounded-lg text-md px-5 py-2.5 content-center text-center items-center mr-2 sm:m-2 bg-slate-400 hover:bg-slate-500 focus:ring-green-800"
            onClick={() => {
              handleCloseDialog();
              setLoading(false);
            }}
          >
            Avbryt
          </button>
        </>
      </form>
    </ReactModal>
  );
};

ChangeLocationDialog.propTypes = {};

export default ChangeLocationDialog;
