import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Activities } from "../enums/Activities";
import { AddModes } from "../enums/AddModes";
import ReactModal from "react-modal";
import FormInput from "./FormInput";
import classNames from "classnames";
import Select from "react-select";

const AddLocationModal = ({ addItemMode, setAddItemMode, coordinates }) => {
  const [activity, setActivity] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [loading, setLoading] = useState(false);

  const handleActivity = (value) => {
    setActivity(value);
  };

  const handleName = (event) => {
    setName(event.target.value);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleWebsite = (event) => {
    setWebsite(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const result = await axios.post("/.netlify/functions/AddItem", {
      name: name,
      type: activity?.value,
      latitude: coordinates.lat,
      longitude: coordinates.lng,
      website: website,
      description: description,
    });

    setLoading(false);
    if (result.status === 200) {
      setAddItemMode(AddModes.OFF);
      toast.info(
        "Da er den sendt avgårde, men den må fremdeles godkjennes manuelt :)",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
      setActivity({ value: "", label: "" });
      setName("");
      setDescription("");
      setWebsite("");
    } else {
      toast.error("Det skjedde noe feil", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <ReactModal
      isOpen={AddModes.DESCRIBE_ITEM === addItemMode}
      style={{ overlay: { zIndex: 9999 } }}
    >
      <h2>På tide å legge til en ny ting!❤️</h2>
      <br />
      <form onSubmit={handleSubmit}>
        <FormInput
          type="text"
          label={"Gi det et navn"}
          placeholder={"Navn"}
          value={name}
          onChange={handleName}
          className={"mb-2"}
        />
        <label
          htmlFor={"aktivitet"}
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Aktivitet
        </label>
        <Select
          value={activity}
          onChange={handleActivity}
          options={Object.values(Activities)
            .sort()
            .map((activity) => {
              return { value: activity, label: activity };
            })}
          placeholder={"Hva er det?"}
        />
        <FormInput
          type="text"
          label={"Nettside"}
          placeholder={"Nettside; om det finnes"}
          value={website}
          onChange={handleWebsite}
          className={"mb-2"}
        />
        <label
          htmlFor={"beskrivelse"}
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Beskrivelse
        </label>
        <textarea
          id={"beskrivelse"}
          className={classNames(
            "bg-gray-50",
            "border border-gray-300",
            "text-gray-900",
            "text-sm",
            "rounded-lg",
            "focus:ring-blue-500",
            "focus:border-blue-500",
            "block",
            "w-full",
            "p-2.5",
            "placeholder-gray-400",
            "focus:ring-blue-500",
            "focus:border-blue-500",
            "mb-2"
          )}
          placeholder={"Beskrivelse..."}
          required
          value={description}
          onChange={handleDescription}
        />
        <button
          className="text-white font-medium rounded-lg text-md px-5 py-2.5 text-center inline-flex items-center mr-2 sm:m-2 bg-green-600 hover:bg-green-700 focus:ring-green-800"
          type="submit"
          disabled={loading}
        >
          {(!loading && "Send inn") || "Sender inn..."}
        </button>
        <button
          type="button"
          className="text-white font-medium rounded-lg text-md px-5 py-2.5 content-center text-center items-center mr-2 sm:m-2 bg-slate-400 hover:bg-slate-500 focus:ring-green-800"
          onClick={() => {
            setAddItemMode(AddModes.OFF);
            setLoading(false);
          }}
        >
          Avbryt
        </button>
      </form>
    </ReactModal>
  );
};

export default AddLocationModal;
