import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";

export const AddressSearch = ({
  selectedAddress = null,
  setSelectedAddress,
  className,
  style,
}) => {
  const [address, setAddress] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const search = async () => {
      setLoading(true);
      const result = await axios.post("/.netlify/functions/AddressSearch", {
        address: address,
      });

      if (result.status === 200) {
        setAddresses(result.data);
      }
      setLoading(false);
    };
    if (address.trim().length !== 0) {
      setTimeout(() => {
        search();
      }, 500);
    }
  }, [address, setAddresses]);

  const handleChangeAddress = (e) => {
    e.preventDefault();
    setAddress(e?.target?.value);
  };

  const getDisplayValue = (address) => {
    return address === null
      ? ""
      : `${address?.adressetekst}, ${address?.poststed}`;
  };

  const handleSetAddress = (address) => {
    setSelectedAddress(address);
  };

  return (
    <div className={`${className}`} style={style}>
      <Combobox onChange={handleSetAddress}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              placeholder={"Hopp til en adresse (under utprøving)"}
              onChange={(e) => handleChangeAddress(e)}
            />
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setAddress("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {addresses.length === 0 && address !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  {loading ? "Laster..." : "Ingenting funnet"}
                </div>
              ) : (
                addresses.map((address) => (
                  <Combobox.Option
                    key={`${address.adressekode}-${address.adressetekst}-${address.kommunenummer}-${address.gardsnummer}-${address.bruksnummer}-${address.festenummer}`}
                    className={({ active }) =>
                      `relative cursor-default select-none text-left pl-3 py-1 pr-4 ${
                        active ? "bg-green-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={address}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {getDisplayValue(address)}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};
