import React from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import ActivityInformation from "./ActivityInformation";
import { getLeafletIconByType } from "../Icons";

const MapMarker = ({ activity, setAddItemMode }) => {
  return (
    <Marker
      position={[activity.latitude, activity.longitude]}
      icon={getLeafletIconByType(activity.type)}
      tabIndex="-1"
    >
      <Tooltip className="max-w-md" tabIndex="-1">
        <div>
          <p>
            <b>{activity.name}</b>
          </p>
          {activity.website && activity.website !== "" && (
            <p>
              Nettside:
              <a
                href={activity.website}
                target={"_blank"}
                rel="noopener noreferrer"
              >
                {activity.website}
              </a>
            </p>
          )}
          <div className="max-w-md break-words">{activity.type}</div>
        </div>
      </Tooltip>
      <Popup
        className="[&>.leaflet-popup-content-wrapper]:opacity-90 [&>.leaflet-popup-content-wrapper]:rounded"
        style={{ opacity: "0.9" }}
        tabIndex="-1"
      >
        <ActivityInformation
          id={activity.id}
          name={activity.name}
          type={activity.type}
          description={activity.description}
          website={activity.website}
          latitude={activity.latitude}
          longitude={activity.longitude}
          setAddItemMode={setAddItemMode}
        />
      </Popup>
    </Marker>
  );
};

export default React.memo(MapMarker);
