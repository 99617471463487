import { useEffect } from "react";
import { useMap } from "react-leaflet";

const MoveToLocation = ({ lat, lng }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng]);
  }, [map, lat, lng]);
  return null;
};

export default MoveToLocation;
