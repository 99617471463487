import L from "leaflet";
import basketball from "./assets/icons/basketball.avif";
import discGolf from "./assets/icons/frisbee.avif";
import pingpong from "./assets/icons/pingpong.avif";
import volleyball from "./assets/icons/volleyball.avif";
import skatepark from "./assets/icons/skate.avif";
import golf from "./assets/icons/golf.avif";
import playground from "./assets/icons/playground.avif";
import iceSkaterink from "./assets/icons/ice-skate.avif";
import outdoorGym from "./assets/icons/training.avif";
import runningTrack from "./assets/icons/running.avif";
import streetSoccer from "./assets/icons/football-goal.avif";
import dogpark from "./assets/icons/dogpark.avif";
import climbing from "./assets/icons/climb.avif";
import swimming from "./assets/icons/swimming.avif";
import tennis from "./assets/icons/tennis.avif";
import toilet from "./assets/icons/toilet.avif";
import bandy from "./assets/icons/bandy.avif";
import sled from "./assets/icons/sled.avif";
import camping from "./assets/icons/camping.avif";
import hammock from "./assets/icons/hammock.avif";
import unknown from "./assets/icons/unknown.avif";
import bike from "./assets/icons/bike.avif";


const getLeafletIcon = (iconUrl) => {
  return new L.Icon({
    iconUrl: iconUrl,
    iconSize: new L.Point(30, 30),
  });
};

const getLeafletIconByType = (type) => {
  switch (type.toLowerCase()) {
    case "basketball":
      return getLeafletIcon(basketball);
    case "bordtennis":
      return getLeafletIcon(pingpong);
    case "volleyball":
      return getLeafletIcon(volleyball);
    case "skatepark":
      return getLeafletIcon(skatepark);
    case "minigolf":
      return getLeafletIcon(golf);
    case "lekeplass":
      return getLeafletIcon(playground);
    case "skøytebane":
      return getLeafletIcon(iceSkaterink);
    case "treningspark":
      return getLeafletIcon(outdoorGym);
    case "løpebane":
      return getLeafletIcon(runningTrack);
    case "ballbinge":
      return getLeafletIcon(streetSoccer);
    case "fotballbane":
      return getLeafletIcon(streetSoccer);
    case "frisbeegolf":
      return getLeafletIcon(discGolf);
    // TODO: Fix return getLeafletIcon(<Grill);
    case "fastgrill":
    case "hundepark":
      return getLeafletIcon(dogpark);
    case "klatrefelt":
      return getLeafletIcon(climbing);
    case "badeplass":
      return getLeafletIcon(swimming);
    case "tennis":
      return getLeafletIcon(tennis);
    case "offentlig toalett":
      return getLeafletIcon(toilet);
    case "innebandy":
      return getLeafletIcon(bandy);
    case "sykkelpark":
      return getLeafletIcon(bike);
    case "akebakke":
      return getLeafletIcon(sled);
    case "teltplass":
      return getLeafletIcon(camping);
    case "hengekøyeplass":
      return getLeafletIcon(hammock);
    case "golfbane":
      return getLeafletIcon(golf);
    case "simulatorgolf":
      return getLeafletIcon(golf);
    default:
      return getLeafletIcon(unknown);
  }
};

const getIconByType = (type) => {
  switch (type.toLowerCase()) {
    case "basketball":
      return basketball;
    case "bordtennis":
      return pingpong;
    case "volleyball":
      return volleyball;
    case "skatepark":
      return skatepark;
    case "minigolf":
      return golf;
    case "lekeplass":
      return playground;
    case "skøytebane":
      return iceSkaterink;
    case "treningspark":
      return outdoorGym;
    case "løpebane":
      return runningTrack;
    case "ballbinge":
      return streetSoccer;
    case "fotballbane":
      return streetSoccer;
    case "frisbeegolf":
      return discGolf;
    // TODO: Fix return <Grill;
    case "fastgrill":
    case "hundepark":
      return dogpark;
    case "klatrefelt":
      return climbing;
    case "badeplass":
      return swimming;
    case "tennis":
      return tennis;
    case "offentlig toalett":
      return toilet;
    case "innebandy":
      return bandy;
    case "sykkelpark":
      return bike;
    case "akebakke":
      return sled;
    case "teltplass":
      return camping;
    case "hengekøyeplass":
      return hammock;
    case "golfbane":
      return golf;
    case "simulatorgolf":
      return golf;
    default:
      return unknown;
  }
};

export { getLeafletIconByType, getIconByType };
