import React from "react";
import { useMapEvent } from "react-leaflet";
import { AddModes } from "../enums/AddModes";

const AddLocation = ({
  setAddItemMode,
  setCoordinates,
}) => {
  useMapEvent({
    click(e) {
      const lat = e?.latlng?.lat;
      const lng = e?.latlng?.lng;
      setCoordinates({ lat, lng });
      setAddItemMode(AddModes.DESCRIBE_ITEM);
    },
  });

  return (
    <div>
      <div
        style={{
          position: "fixed",
          margin: "auto",
          top: "0",
          left: "0",
          transform: "translate(calc(50vw - 50%), calc(1vh))",
          zIndex: 9999,
        }}
        className="bg-slate-50 border-2 p-2 border-green-700 cursor-text rounded-xl"
      >
        Du skal nå legge til et nytt element. <br />
        Trykk et sted på kartet for å markere hvor det er. <br />
        Trykk på markør-ikonet for å finne deg selv.
      </div>
    </div>
  );
};

export default AddLocation;
