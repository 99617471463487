const Activities = Object.freeze({
  BASKETBALL: "Basketball",
  BORDTENNIS: "Bordtennis",
  VOLLEYBALL: "Volleyball",
  SKATEPARK: "Skatepark",
  MINIGOLF: "Minigolf",
  LEKEPLASS: "Lekeplass",
  SKOYTEBANE: "Skøytebane",
  TRENINGSPARK: "Treningspark",
  BALLBINGE: "Ballbinge",
  LOPEBANE: "Løpebane",
  SIMULATORGOLF: "Simulatorgolf",
  FOTBALLBANE: "Fotballbane",
  FRISBEEGOLF: "Frisbeegolf",
  HUNDEPARK: "Hundepark",
  KLATREFELT: "Klatrefelt",
  BADEPLASS: "Badeplass",
  TENNIS: "Tennis",
  OFFENTLIG_TOALETT: "Offentlig Toalett",
  INNEBANDY: "Innebandy",
  SYKKELPARK: "Sykkelpark",
  PADELBANE: "Padel",
  AKEBAKKE: "Akebakke",
  TELTPLASS: "Teltplass",
  HENGEKOYE: "Hengekøyeplass",
  GOLFBANE: "Golfbane",
  FASTGRILL: "Fastgrill",
  ANNET: "Annet",
});

export { Activities };
