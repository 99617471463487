import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import Sheet from "react-modal-sheet";
import { Activities } from "../enums/Activities";
import { AddModes } from "../enums/AddModes";
import { getIconByType } from "../Icons";
import { AddressSearch } from "./AddressSearch";

const Filterpanel = ({
  className,
  style,
  filters,
  handleChangeFilter,
  handleChangeAllFilters,
  addItemMode,
  setAddItemMode,
  screenWidth,
  selectedAddress,
  setSelectedAddress,
}) => {
  const ref = useRef();
  const constraintsRef = useRef(null);
  const snapTo = (i) => ref.current?.snapTo(i);
  const [maxConstraints, setMaxConstraints] = useState(1000);
  const [icon, setIcon] = useState(getIconByType(Activities.BORDTENNIS));

  useEffect(() => {
    setMaxConstraints(
      -constraintsRef?.current?.scrollWidth +
        constraintsRef?.current?.clientWidth
    );
  }, [constraintsRef]);

  useEffect(() => {
    setIcon(getIconByType(randomProperty(Activities)));
  }, []);

  return (
    <>
      {(screenWidth >= 640 && (
        <div
          className={`${className} rounded-t-3xl md:rounded-none bg-slate-50`}
          style={style}
        >
          {createPanelContent(false, icon)}
        </div>
      )) || (
        <Sheet
          ref={ref}
          isOpen={addItemMode === AddModes.OFF}
          onClose={() => snapTo(0)}
          detent={"content-height"}
          snapPoints={[260, 100]}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content ref={constraintsRef}>
              {createPanelContent(screenWidth <= 768, icon)}
            </Sheet.Content>
          </Sheet.Container>
        </Sheet>
      )}
    </>
  );

  function renderDesktopActivities() {
    return (
      <ul className="flex sm:flex-col py-3 overflow-x-scroll sm:bounce">
        {renderActivities()}
      </ul>
    );
  }

  function renderMobileActivities() {
    return (
      <motion.ul
        drag={"x"}
        dragDirectionLock
        dragConstraints={{
          left: maxConstraints,
          right: 0,
        }}
        className="filters-list flex sm:flex-col py-3 sm:bounce md:overflow-scroll"
      >
        {renderActivities()}
      </motion.ul>
    );
  }

  function renderActivities() {
    return Object.values(Activities).map((activity) => {
      return (
        <li
          className="flex flex-col sm:flex-row mx-3 sm:mx-5 max-w-lg min-w-fit"
          key={activity}
        >
          <input
            type="checkbox"
            id={`${activity}-option`}
            className="hidden peer"
            onChange={() => handleChangeFilter(activity)}
            checked={filters.has(activity)}
          />
          <label
            htmlFor={`${activity}-option`}
            className="inline-flex flex-col sm:flex-row  sm:mb-2 justify-between sm:justify-start items-center w-full rounded-lg cursor-pointer [&>img]:peer-checked:ring-0 [&>img]:peer-checked:grayscale"
          >
            <img
              src={getIconByType(activity)}
              alt={activity}
              className="w-10 h-10 sm:mr-4 self-center ring ring-black rounded-full"
            />
            <div className="text-center items-center">{activity}</div>
          </label>
        </li>
      );
    });
  }

  function createPanelContent(isMobile = false, icon) {
    return (
      <div className="flex flex-col md:h-full sm:pt-3">
        <div className="flex flex-row sm:flex-col justify-between pb-2 md:pb-3 border-b border-stone-300">
          <div className="flex ml-4">
            <h2 className="text-2xl">
              <img
                src={icon}
                className="w-10 h-10 sm:mr-2 self-center rounded-full inline-block"
                alt="random icon"
              />
              Leike
            </h2>
          </div>

          <button
            type="button"
            className={`text-white ${
              addItemMode === AddModes.OFF ? "bg-green-700" : "bg-red-500"
            } justify-center font-medium rounded-lg text-sm mr-6 sm:mx-6 sm:my-2 px-5 py-2.5 text-center inline-flex content-center items-center bg-green-600 hover:bg-green-700 focus:ring-green-800`}
            onClick={() =>
              setAddItemMode(
                addItemMode === AddModes.OFF
                  ? AddModes.CHOOSE_COORDINATES
                  : AddModes.OFF
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            <span>{addItemMode === AddModes.OFF ? "Legg til" : "Avbryt"}</span>
          </button>
        </div>
        <div className="flex sm:flex-col text-left justify-between ml-4 mr-4 my-2 md:my-3">
          {screenWidth >= 640 && (
            <AddressSearch
              selectedAddress={selectedAddress}
              setSelectedAddress={setSelectedAddress}
            />
          )}
          <h3 className="text-lg">Filtre</h3>
          <button
            type="button"
            className="text-whitefont-medium rounded-lg text-sm px-5 py-2.5 content-center text-center items-center mr-2 sm:m-2 bg-slate-400 hover:bg-slate-500 focus:ring-green-800"
            onClick={() => handleChangeAllFilters()}
          >
            {Object.values(Activities).length > filters.size
              ? "Skjul alle"
              : "Vis alle"}
          </button>
        </div>
        {isMobile ? renderMobileActivities() : renderDesktopActivities()}
      </div>
    );
  }
};

const randomProperty = (obj) => {
  var keys = Object.keys(obj);
  return obj[keys[(keys.length * Math.random()) << 0]];
};

export default Filterpanel;
