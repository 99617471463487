import { useMapEvents } from "react-leaflet";

const MapEvents = ({ setViewPosition }) => {
  const updateViewPosition = (e) => {
    const center = e.target.getCenter();
    const zoom = e.target.getZoom();
    setViewPosition({ lat: center.lat, lng: center.lng, zoom: zoom });
  };

  useMapEvents({
    dragend: (e) => {
      updateViewPosition(e);
    },
    zoomend: (e) => {
      updateViewPosition(e);
    },
  });

  return null;
};

export default MapEvents;
