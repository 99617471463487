import React, { useState } from "react";
import { AddModes } from "../enums/AddModes.js";
import { mapsSelector } from "../Utils/MapUtil.js";
import ChangeLocationDialog from "./ChangeLocationDialog.js";

const ActivityInformation = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    props.setAddItemMode(AddModes.EDIT_ITEM);
    setIsDialogOpen(true);
  };
  const handleCloseDialog = () => {
    props.setAddItemMode(AddModes.OFF);
    setIsDialogOpen(false);
  };

  return (
    <>
      <div>
        <p>
          <b>{props.name}</b>
        </p>
        <em>{props.type}</em>
        {props.website && props.website !== "" && (
          <p>
            Nettside:
            <a href={props.website} target={"_blank"} rel="noopener noreferrer">
              {props.website}
            </a>
          </p>
        )}
        <p>{props.description}</p>
        <button
          className="text-white font-medium rounded-lg text-md px-5 py-2.5 text-center inline-flex items-center mr-2 sm:m-2 bg-green-600 hover:bg-green-700 focus:ring-green-800"
          target="_blank"
          onClick={() => mapsSelector(props.latitude, props.longitude)}
        >
          Veibeskrivelse
        </button>
        <button
          type="button"
          className="text-white font-medium rounded-lg text-md px-5 py-2.5 content-center text-center items-center mr-2 sm:m-2 bg-slate-400 hover:bg-slate-500 focus:ring-green-800"
          variant="outlined"
          target="_blank"
          onClick={handleOpenDialog}
        >
          Endre
        </button>
        <span
          style={{
            position: "absolute",
            bottom: "2px",
            right: "5px",
            color: "#BBB",
          }}
        >
          {props.id}
        </span>
      </div>
      <ChangeLocationDialog
        activity={props}
        isOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
      />
    </>
  );
};

export default React.memo(ActivityInformation);
