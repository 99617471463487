import L from "leaflet";

const mapsSelector = (lat, long) => {
  if (
    navigator.platform.indexOf("iPhone") !== -1 ||
    navigator.platform.indexOf("iPad") !== -1 ||
    navigator.platform.indexOf("iPod") !== -1
  ) {
    window.open(`maps://maps.google.com/maps?daddr=${lat},${long}&amp;ll=`);
  } else {
    window.open(`https://maps.google.com/maps?daddr=${lat},${long}&amp;ll=`);
  }
};

const createClusterCustomIcon = function (cluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "cluster-point",
    iconSize: L.point(30, 30, true)
    
  });
};

export { mapsSelector, createClusterCustomIcon };
