import React from "react";
import { useMap } from "react-leaflet";
import MapMarker from "./MapMarker";

const Markers = ({ filteredActivities, setAddItemMode }) => {
  const leafletMap = useMap();
  return (
    <>
      {filteredActivities
        .filter(
          (activity) =>
            isFinite(activity.latitude) && isFinite(activity.longitude)
        )
        .filter((activity) =>
          leafletMap.getBounds().contains({
            lat: activity.latitude,
            lng: activity.longitude,
          })
        )
        .map((activity, index) => {
          return (
            <MapMarker
              activity={activity}
              key={index}
              setAddItemMode={setAddItemMode}
            />
          );
        })}
    </>
  );
};

export default Markers;
