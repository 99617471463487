import { useEffect } from "react";
import { useMap } from "react-leaflet";
import Locate from "leaflet.locatecontrol";

const Location = () => {
  const leafletMap = useMap();
  useEffect(() => {
    const lc = new Locate({
      position: "topleft",
      flyTo: true,
      locateOptions: {
        enableHighAccuracy: true,
      },
    });
    lc.addTo(leafletMap);
    return () => {
      lc.remove();
    };
  }, [leafletMap]);
  return null;
};

export default Location;
