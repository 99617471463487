import axios from "axios";
import { React, useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AddLocation from "./components/AddLocation";
import AddLocationModal from "./components/AddLocationModal";
import { AddressSearch } from "./components/AddressSearch";
import Filterpanel from "./components/Filterpanel";
import Location from "./mapFunctions/Location";
import MapEvents from "./mapFunctions/MapEvents";
import MarkerClusterGroup from "./components/MarkerClusterGroup";
import Markers from "./components/Markers";
import MoveToLocation from "./mapFunctions/MoveToLocation";
import { Activities } from "./enums/Activities";
import { AddModes } from "./enums/AddModes";
import { createClusterCustomIcon } from "./Utils/MapUtil";

function App() {
  const [activities, setActivities] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [filters, setFilters] = useState(new Set(["None"]));
  const [addItemMode, setAddItemMode] = useState(AddModes.OFF);
  const [coordinates, setCoordinates] = useState({});
  const [viewPosition, setViewPosition] = useState({
    lat: 59.921141,
    lng: 10.753914,
    zoom: 13,
  });
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    return (_) => {
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
    };
  }, []);

  useEffect(() => {
    setIsMobile(width <= 768);
  }, [width]); 

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axios.get(
          "/.netlify/functions/spreadsheetFunctions"
        );
        setActivities(response.data);
      } catch (exception) {
        console.log(exception);
      }
    };
    fetchActivities();
  }, []);

  useEffect(() => {
    let storedFilters = JSON.parse(window.localStorage.getItem("filters"));
    if (!Array.isArray(storedFilters)) {
      window.localStorage.setItem("filters", JSON.stringify([])); 
      storedFilters = [];
    }

    setFilters(new Set(storedFilters));
  }, []);

  useEffect(() => {
    filters &&
      !(filters.size === 1 && filters.has("None")) &&
      localStorage.setItem("filters", JSON.stringify(Array.from(filters)));
  }, [filters]);

  useEffect(() => {
    setFilteredActivities(
      activities.filter((activity) => filters && !filters.has(activity?.type))
    );
  }, [filters, activities, selectedAddress]);

  /**
   * Removes filter if present, adds filter if not
   * @param {*} activity
   */
  const handleChangeFilter = (activity) => {
    const tempFilters = new Set(filters);
    if (!tempFilters.delete(activity)) {
      tempFilters.add(activity);
    }
    setFilters(tempFilters);
  };

  const handleChangeAllFilters = () => {
    if (filters.size !== Object.values(Activities).length) {
      setFilters(new Set(Object.values(Activities)));
    } else {
      setFilters(new Set());
    }
  };

  return (
    <div className="app flex">
      <AddLocationModal
        addItemMode={addItemMode}
        coordinates={coordinates}
        activities={Activities}
        setAddItemMode={setAddItemMode}
        style={{ zIndex: 9900 }}
      />
      {width <= 640 && (
        <AddressSearch
          className={"fixed top-2 mx-auto inset-x-0 w-2/3"}
          //FIXME: hack to override leaflet
          style={{ zIndex: 9900 }}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
        />
      )}
      <Filterpanel
        className={`fixed sm:static bottom-0 sm:bottom-none w-full sm:w-4/12 lg:w-[25%] xl:w-[20%] 2xl:w-[15%] md:h-full shadow-[0_-5px_5px_5px_rgba(0,0,0,0.3)] ${
          width <= 640 && addItemMode !== AddModes.OFF ? "hidden" : ""
        }`}
        //hack to override leaflet
        style={{ zIndex: 9900 }}
        filters={filters}
        handleChangeFilter={handleChangeFilter}
        handleChangeAllFilters={handleChangeAllFilters}
        addItemMode={addItemMode}
        setAddItemMode={setAddItemMode}
        screenWidth={width}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
      />

      <MapContainer
        className="mapContainer flex-grow"
        center={[viewPosition.lat, viewPosition.lng]}
        zoom={viewPosition.zoom}
        tap={false}
        preferCanvas={true}
        attributionControl={!isMobile} //TODO: Fix
      >
        <>
          {/* Components altering map */}
          <Location />
          {selectedAddress?.representasjonspunkt?.lat &&
            selectedAddress?.representasjonspunkt?.lon && (
              <MoveToLocation
                lat={selectedAddress?.representasjonspunkt?.lat}
                lng={selectedAddress?.representasjonspunkt?.lon}
              />
            )}
          <MapEvents setViewPosition={setViewPosition} />
        </>
        {addItemMode === AddModes.CHOOSE_COORDINATES && (
          <AddLocation
            setAddItemMode={setAddItemMode}
            setCoordinates={setCoordinates}
          />
        )}
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          //TODO: Move attribution
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup
          maxClusterRadius={10}
          iconCreateFunction={createClusterCustomIcon}
          zoomToBoundsOnClick={false}
        >
          <Markers
            filteredActivities={filteredActivities}
            setAddItemMode={setAddItemMode}
          />
        </MarkerClusterGroup>
      </MapContainer>
      <ToastContainer />
    </div>
  );
}

export default App;
